<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Tabular </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateTabular">
              <CRow>
              <CCol sm="12">
                <CInput
                  v-model="nama_tabular"
                  label="Tabular"
                  placeholder="Input Tabular"
                />
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Kota/Kabupaten</label>
                  <select class="form-control" @change="cariKecamatan()" v-model="id_kategori">
                    <option value="" disabled selected>-- Pilih Kota/Kabupaten --</option>
                    <option v-for="r in kategoris" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Kecamatan</label>
                  <select class="form-control" @change="cariKelurahan()" v-model="id_subkategori">
                    <option value="" disabled selected>-- Pilih Kecamatan --</option>
                    <option v-for="r in subkategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Kelurahan/Desa</label>
                  <select class="form-control" v-model="id_subsubkategori">
                    <option value="" disabled selected>-- Pilih Kelurahan/desa --</option>
                    <option v-for="r in subsubkategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>
              <!-- <CCol sm="3">             
                <div class="form-group">
                <label>Infrastruktur</label>
                  <select class="form-control" v-model="infrastruktur">
                    <option value="" disabled selected>-- Pilih Tipe Infrastruktur --</option>
                    <option v-for="r in infrastrukturs" :value="r.toLowerCase()">{{r}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Sub Infrastruktur</label>
                  <select class="form-control" v-model="sub_infrastruktur">
                    <option value="" selected>-- Pilih Tipe Infrastruktur --</option>
                    <option v-for="r in subinfrastrukturs" :value="r.toLowerCase()">{{r}} </option>
                  </select>
                </div>
              </CCol> -->
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Subkategori</label>
                  <select class="form-control" v-model="id_subkategori">
                    <option value="" disabled selected>-- Pilih Kategori --</option>
                    <option v-for="r in subkategori" :value="r.id_subkategori">{{r.nama_subkategori}} </option>
                  </select>
                </div>
              </CCol> -->
              <!-- <CCol sm="3">
                <div class="form-group">
                <label>Tampilkan Label</label>
                <select v-model="tampilkan_label" placeholder="-- Pilihan --" class="form-control">
                  <option value="" disabled selected>-- Pilihan --</option>
                  <option value="1">Ya</option>
                  <option value="0">Tidak</option>
                </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Jenis</label>
                <select v-model="jenis_tabular" placeholder="-- Pilihan --" class="form-control">
                  <option value="" disabled selected>-- Pilihan --</option>
                  <option value="1">Point</option>
                  <option value="2">Line</option>
                  <option value="3">Polygon</option>
                </select>
                </div>
              </CCol> -->
              <!-- <CCol sm="3">
                <div class="form-group">
                  <label>Icon</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                    <input type="hidden" v-model="foto1">
                  </div>
                </div>  
              </CCol> -->
              <CCol sm="3">
                <div class="form-group">
                <label>Publisitas</label>
                <select v-model="publisitas_tabular" placeholder="-- Pilihan --" class="form-control">
                  <option value="" disabled selected>-- Pilihan --</option>
                  <option value="1">Ya</option>
                  <option value="0">Tidak</option>
                </select>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>File Tabular *file SHP yg sdh di.zip</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder2" aria-describedby="inputGroupFileAddon02">
                    <label class="custom-file-label" for="inputGroupFile02">{{placeholder2}}</label>
                    <input type="hidden" v-model="foto2">
                  </div>
                </div>  
              </CCol>
            </CRow>
            <!-- <CRow>
              <CCol sm="12">
              <hr/><center>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style="width: 10%; text-align: center;">Baris</th>
                    <th>Foto</th>
                    <th style="width: 30%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in details" :key="row.id_foto_shp">
                    <td style="text-align: center">{{row.urutan}}</td>
                    <td>
                      <img :src="'https://semari-perkim.com/data/'+row.foto_shp" class="img-fluid w-20" />
                    </td>
                    <td>
                      <CButton type="button" @click="modalAdd=true; id_foto_shp=row.id_foto_shp;" color="success" class="form-control-sm " style="margin-right:10px">Update Foto</CButton>
                    </td>
                  </tr>
                </tbody>
              </table></center>
              </CCol>  
            </CRow>        -->
                <div class="form-actions float-right">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/tabular">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>

    <CModal
      title="Error Foto"
      color="danger"
      :show.sync="modalErrorFoto"
    >
      <b>Error</b>! Data Foto Tidak Boleh Kosong.
    </CModal>

    <CModal
      title="Update Foto"
      color="secondary"
      :show.sync="modalAdd"
    >   
      <div class="form-group">
        <label>Foto</label><br/>                  
        <div class="custom-file">
          <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
          :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
          <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
          <input type="hidden" v-model="foto1">
        </div>
      </div>
      <template #footer>
        <CButton @click="modalAdd = false" color="secondary">Batal</CButton>
        <CButton @click="updateDetailFoto" color="success">Simpan</CButton>
      </template> 
    </CModal>
   
  </div>   
</template>
<script>

import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      modalAdd : false,
      modalErrorFoto:false,
      nama_tabular : "",
      nama_kategori : "",
      id_parent : "",
      tampilkan_label : "",
      jenis_tabular : "",
      publisitas_tabular : "",
      // icon_tabular_text : "",
      // icon_tabular : "",
      file_tabular_text : "",
      file_tabular : "",
      foto2 : "",
      foto1 : "",
      id_kategori : "",
      id_subkategori : "",
      id_subsubkategori : "",
      infrastruktur : "",
      sub_infrastruktur : "",
      id_foto_shp : "",
      foto_shp : "",
      foto_shp_text : "",
      placeholder : "Pilih File",
      placeholder2 : "Pilih File",
      selected : [], // Must be an array reference!
      kategoris : [],
      details : [],
      // subkategori : [],
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',
      kategoris : [],
      subkategori : [],
      subsubkategori : [],
      infrastrukturs : ['Drainase','Jalan','Jembatan'],
      subinfrastrukturs : ['Jalan Baik','Jalan Rusak','Jalan Tanah', 'Jalan Beton', 'Jalan Aspal'],
      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showTabular();
  },
  methods: {
    cariKecamatan: function(){
      axios.get(process.env.VUE_APP_BASE_URL+"kategoris/filter?level=2&id_parent="+this.id_kategori)
        .then((response) => {
              this.subkategori=response.data;
        });
    },
    cariKelurahan: function(){
      axios.get(process.env.VUE_APP_BASE_URL+"kategoris/filter?level=3&id_parent="+this.id_subkategori)
        .then((response) => {
              this.subsubkategori=response.data;
        });
    },

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"kategoris/filter?level=1&id_parent=0")
        .then((response) => {
              this.kategoris=response.data;
        });

      axios.get(process.env.VUE_APP_BASE_URL+"tabulars/detail/"+this.$route.params.id_tabular)
        .then((response) => {
              this.details=response.data;
        });
    },

    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.foto_shp_text = fileData.name;
      this.foto_shp = fileData;
      this.placeholder = fileData.name;
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.file_tabular_text = fileData.name;
      this.file_tabular = fileData;
      this.placeholder2 = fileData.name;
    },

    updateTabular: function(event){
      
      const formData = new FormData()

        formData.append('id_tabular', this.$route.params.id_tabular)
        formData.append('nama_tabular', this.nama_tabular)
        formData.append('id_kategori', this.id_kategori)
        formData.append('id_subkategori', this.id_subkategori)
        formData.append('id_subsubkategori', this.id_subsubkategori)
        // formData.append('infrastruktur', this.infrastruktur)
        // formData.append('sub_infrastruktur', this.sub_infrastruktur)
        // formData.append('tampilkan_label', this.tampilkan_label)
        // formData.append('jenis_tabular', this.jenis_tabular)
        formData.append('publisitas_tabular', this.publisitas_tabular)
        formData.append('foto2', this.foto2)

        // if (this.foto_shp_text) {
        //   formData.append('foto_shp', this.foto_shp, this.foto_shp.name) 
        //   formData.append('foto_shp_text', this.foto_shp.name)
        // } else{
        //   modalErrorFoto = true;
        // }

        if (this.file_tabular_text) {
          // formData.append('file_tabular', this.file_tabular, this.file_tabular.name) 
          formData.append('file_tabular_text', this.file_tabular.name)
        }
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"tabular/"+this.$route.params.id_tabular, formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/tabular');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    updateDetailFoto: function(event){
      
      const formData = new FormData()

        formData.append('id_foto_shp', this.id_foto_shp)
        // formData.append('id_tabular', this.id_tabular)
        // formData.append('urutan', this.urutan)
        formData.append('foto1', this.foto1)

        if (this.foto_shp_text) {
          formData.append('foto_shp', this.foto_shp, this.foto_shp.name) 
          formData.append('foto_shp_text', this.foto_shp.name)
        } else{
          modalErrorFoto = true;
        }

      axios.post(process.env.VUE_APP_BASE_URL+"tabular/update/"+this.id_foto_shp, formData)
        .then((response) => {

            this.modalAdd=false;
            if(response.data=='error'){
              this.modalError = true;
            }
            else{
              this.loadData;
            }             
        })
    },
    
    showTabular: function(event){
      // Simple POST request with a JSON body using axios
      const tabular = { 
                    nama_tabular:this.nama_tabular,
                    nama_kategori: this.nama_kategori, 
                    nama_subkategori: this.nama_subkategori,
                    nama_subsubkategori: this.nama_subsubkategori,
                    // tampilkan_label: this.tampilkan_label,
                    // jenis_tabular: this.jenis_tabular,
                    // publisitas_tabular: this.publisitas_tabular,
                    // infrastruktur: this.infrastruktur,
                    // sub_infrastruktur: this.sub_infrastruktur,
                    file_tabular: this.file_tabular
                    // foto_shp: this.foto_shp
                    };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"tabular/"+this.$route.params.id_tabular)
        .then((response) => {
            
              this.nama_tabular=response.data.nama_tabular;
              this.nama_kategori=response.data.id_kategori;
              // this.nama_subkategori=response.data.id_subkategori;
              this.id_kategori=response.data.id_kategori;
              this.cariKecamatan();
              this.id_subkategori=response.data.id_subkategori;
              this.cariKelurahan();
              this.id_subsubkategori=response.data.id_subsubkategori;
              // this.infrastruktur=response.data.infrastruktur;
              // this.sub_infrastruktur=response.data.sub_infrastruktur;
              // this.tampilkan_label=response.data.tampilkan_label;
              // this.infrastruktur=response.data.infrastruktur;
              // this.sub_infrastruktur=response.data.sub_infrastruktur;
              // this.jenis_tabular=response.data.jenis_tabular;
              this.publisitas_tabular=response.data.publisitas_tabular;
              // this.placeholder1=response.data.foto_shp;
              // this.foto1=response.data.foto_shp;
              this.placeholder2=response.data.file_tabular;
              this.foto2=response.data.file_tabular;            
        })
    }
  }
}
</script>